export const colors = [
    '#FEC20A', // yellow
    '#D56EEF', // purple 
    '#3292D7', // blue 
    '#D73252', // maroon
    '#3EC1A5', // cyan
    '#57CD32', // green
    '#FF0D00', // red
    '#D87127', // brown
    '#EB14BC', // pink
    '#B0B44B', // olive
];
